@import '../../variables.module';

@keyframes errorSlide {
  from {
    height: 0;
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }

  to {
    height: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.container {
  .input:focus-within {
    border-bottom-color: $primary;
  }
}

.input {
  border: 1px solid $border-primary;
  border-bottom-width: 2px;
  border-top-left-radius: 3px;
  box-sizing: border-box;
  font-size: 26px;
  height: 75px;
  margin-right: 15px;
  outline: none;
  text-align: center;
  width: 70px;

  @include xs {
    // stylelint-disable declaration-property-unit-allowed-list
    height: calc(23vw - 15px);
    width: calc(23vw - 15px);
  }

  &.error {
    border-bottom-color: $error;
  }

  &:last-child {
    margin-right: 0;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}

.error-message {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  color: $error;
  display: flex;
  font-size: 14px;
  margin: 10px 0;
  width: 100%;
}
