@import '../../variables.module';

.card {
  background-color: $bg-secondary;
  border: 1px solid $border-primary;
  border-radius: 7px;
  display: flex;
  padding: 30px 25px 25px;
  position: relative;

  @include xs {
    border-width: 0;
  }
}
