@import '../../variables.module';

@keyframes bottomFadeIn {
  from {
    opacity: 0;
    transform: translateY(30%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.overlay {
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  @include xs {
    bottom: unset;
  }
}

.container {
  max-height: 100%;
  max-width: 720px;

  @include s {
    padding: 10px;
  }
}

.fade {
  animation: bottomFadeIn 500ms ease-in-out;
}

.card {
  border-style: none;
  position: relative;
  z-index: 1;
}

.close-button {
  background-color: transparent;
  border-style: none;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 21px;
  top: 18px;
}

.close {
  height: 13px;
  width: 13px;
}
