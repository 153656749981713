@import '../../variables.module';

.container {
  align-items: center;
  background-color: $bg-primary;
  border: 1px solid $border-primary;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  padding: 20px 15px;
  position: relative;
  width: 100%;

  &.error {
    background-color: $bg-error-light;
    border-color: $error;
  }
}

.ripples {
  border-radius: 10px;
  width: 100%;
}

.focusable {
  &:focus-within {
    box-shadow: 0 0 8px 0 rgba($primary, 60%);
  }
}

.input {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.icon {
  max-width: 85px;
}

.preview {
  background-size: cover;
  display: flex;
  height: 100px;
  justify-content: center;
  position: relative;
  width: 85px;

  .overlayed-plus {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.text {
  flex-grow: 1;
  margin-left: 20px;

  .title {
    color: $primary;
    line-height: 19px;
    margin-bottom: 5px;

    &.error {
      color: $error;
    }
  }
}

.plus {
  &.error {
    fill: $error;

    circle {
      stroke: $error;
    }
  }
}

.delete {
  background-color: transparent;
  border-radius: 50%;
  border-width: 0;
  box-sizing: content-box;
  cursor: pointer;
  flex-shrink: 0;
  height: 24px;
  margin: 0;
  outline: none;
  padding: 15px;
  position: relative;
  width: 24px;

  &:active {
    background-color: darken($bg-primary, 2%);
  }
}

.error-wrapper {
  display: flex;
  margin-top: 5px;

  .error-icon {
    flex-shrink: 0;
    margin-right: 10px;
    position: relative;
    top: 4px;
  }

  .error {
    color: $error;
  }
}
