@import-normalize;

body {
  font-family: 'Sofia Pro', sans-serif;
  margin: 0;
}

input,
button,
select,
textarea {
  font-family: inherit;
}

/* stylelint-disable property-no-vendor-prefix, selector-no-qualifying-type */
input[type='number'] {
  -moz-appearance: textfield;
}
/* stylelint-enable property-no-vendor-prefix, selector-no-qualifying-type */
