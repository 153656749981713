@import '../../variables.module';

.container {
  display: flex;
  flex-direction: column;

  @include xs {
    border-width: 0;
  }
}

.head {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px 0;
}

.title {
  align-items: center;
  color: $primary;
  display: flex;
  margin-left: 15px;
}

.chevron {
  transition: transform 0.2s ease-in-out;
}

.open {
  transform: rotate(-90deg);
}

.icon {
  display: flex;
  justify-content: center;
  margin-right: 15px;
  width: 30px;
}
