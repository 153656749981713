@import '../../variables.module';

.card {
  padding: 0;
}

.header {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 10px 15px;
  text-align: left;

  @include s {
    font-size: 16px;
  }
}

.header-title {
  margin-right: 20px;

  @include xs {
    font-size: 21px;
  }
}

.tooltip {
  background-color: #fff;
  border-radius: 5px;
  bottom: 76px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 14px;
  padding: 10px;
  position: absolute;
  transform: translate3d(0, 0, 0);

  @include xs {
    bottom: 113px;
    padding: 5px;
  }

  &::after {
    background: #fff;
    bottom: -5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 rgba(0, 0, 0, 0.2);
    content: '';
    height: 10px;
    left: calc(50% - 5px);
    position: absolute;
    transform: rotate(-45deg);
    width: 10px;
    z-index: -1;
  }
}

.window-options {
  margin-left: auto;
}

.close {
  padding-top: 10px;
}

.content {
  background-color: #f3f7fa;
  max-height: 545px;
  overflow: auto;
  padding: 60px 16px 8px;
  position: relative;
  @media only screen and (max-height: 825px) {
    max-height: 350px;
  }
}

.banner {
  background-color: $primary;
  border-radius: 5px;
  color: $bg-secondary;
  display: flex;
  justify-content: center;
  max-width: 672px;
  padding: 10px;
  position: fixed;
  text-align: start;
  transform: translateY(-60px);
  width: 100%;
  z-index: 100;

  @include s {
    border-radius: 0;
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 90%;
  }
}

.banner span {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: super;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  @include xs {
    flex-direction: column;
  }
}

.button {
  max-width: 50%;

  @include xs {
    max-width: 100%;
  }
}

.print {
  color: $primary;
  font-size: 15px;
  font-weight: 400;
  margin: 0 10px;
  min-width: 60px;
  text-align: center;

  @include xs {
    min-width: auto;
  }
}

.print-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  @include xs {
    justify-content: center;
    padding-top: 10px;
  }
}

.pdf {
  fill: $primary;
  height: 28px;
  width: 22px;
}

.print-frame {
  border-style: none;
  height: 0;
  position: absolute;
  width: 0;
}

@media print {
  .content {
    background-color: #fff;
    font-size: 14px;
    height: 100%;
    left: 0;
    line-height: 18px;
    margin: 0;
    max-height: none;
    padding: 15px;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .footer,
  .header {
    display: none;
  }
}
