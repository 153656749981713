@import '../../variables.module';

.wrapper {
  padding: 40px 0 10px;
}

.heading {
  color: $text-secondary;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 65px;
}

.slider {
  padding: 20px;
}

.thumb {
  bottom: -9px;

  &:focus {
    outline: none;
  }
}

.handle {
  align-items: center;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

.tooltip {
  background-color: #fff;
  border-radius: 5px;
  bottom: 60px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  position: absolute;
  transform: translate3d(0, 0, 0);

  &::after {
    background: #fff;
    bottom: -5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    content: '';
    height: 10px;
    left: calc(50% - 5px);
    position: absolute;
    transform: rotate(-45deg);
    width: 10px;
    z-index: -1;
  }
}

.tooltip-right {
  right: 0;

  &::after {
    left: calc(100% - 25px);
  }
}

.tooltip-left {
  left: 0;

  &::after {
    left: 15px;
  }
}

.amount {
  align-items: flex-end;
  background: #fff;
  border-radius: 100px;
  display: flex;
  height: auto;
  justify-content: center;
  min-width: 50px;
  padding: 6px 8px 7px;
  white-space: nowrap;
  width: auto;

  .value {
    font-size: 24px;
    font-weight: 700;
  }

  .units {
    color: $text-auxiliary;
    font-size: 12px;
    margin-left: 5px;
  }
}

.track {
  border-bottom: 4px solid $primary;
  height: 9px;

  &:nth-of-type(1) {
    background-image: url('../../assets/icons/ruler.svg');
    background-position: -20px 0;
  }

  &:nth-of-type(2) {
    background-image: url('../../assets/icons/ruler.svg');
    background-position: right;
    border-bottom: 4px solid $muted;
  }
}

.limits {
  color: $text-auxiliary;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-top: 15px;
}
