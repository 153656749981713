@import '../../variables.module';

.bar {
  background-color: $border-primary;
  border-radius: 16px;
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.fill {
  background-color: $secondary;
  border-radius: 16px;
  display: inline-block;
  height: 4px;
  position: absolute;
}
