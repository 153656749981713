@import '../../variables.module';

.container {
  display: flex;
  width: 100%;
}

.ripples {
  border-radius: 9px;
  width: 100%;

  &:focus-within {
    box-shadow: 0 0 8px 0 rgba($primary, 60%);

    .button:hover {
      border-color: $border-primary;
    }
  }
}

.button {
  background-color: $bg-secondary;
  border: 1px solid $border-primary;
  border-radius: 9px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 100%;

  &:hover {
    border-color: darken($border-primary, 10%);
  }
}

.logo-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  padding: 0 8px;
  width: 100%;
}

.footer {
  align-items: center;
  background-color: $bg-primary;
  color: $text-secondary;
  display: flex;
  font-size: 15px;
  justify-content: center;
  line-height: 18px;
  min-height: 48px;
  width: 100%;
}
