@import '../../variables.module';

.calculator-wrapper {
  background-color: $bg-secondary;
  height: 100vh;
  overflow: auto;
  padding: 0 30px;

  @include m {
    border-width: 0;
    height: auto;
    padding: 0 20px;
  }
}

.close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;
  padding: 10px 0 0;

  @include m {
    display: none;
  }
}

.close-icon {
  cursor: pointer;
}

.button-section {
  margin: 30px 0;

  @include m {
    margin: 20px 0 27px;
  }
}

.mobile-swiper-wrapper {
  display: none;

  @include m {
    display: block;
    margin: 0 -20px;
  }
}

.desktop-slider-wrapper {
  display: block;
  margin-top: -30px;

  @include m {
    display: none;
  }
}

.representative-example {
  color: $text-muted;
  font-size: 12px;
  margin-top: 20px;
}
