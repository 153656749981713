@import '../../variables.module';

.link {
  align-items: center;
  background-color: transparent;
  border-width: 0;
  color: $primary;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  outline: none;
  text-decoration: none;

  svg {
    fill: $primary;
  }

  &:hover {
    color: darken($primary, 5%);
  }

  &:hover svg {
    fill: darken($primary, 5%);
  }

  &.l {
    font-size: 17px;
  }

  &.m {
    font-size: 15px;
  }

  &.s {
    font-size: 13px;
  }

  &.disabled {
    color: $muted;
    cursor: not-allowed;

    &:hover {
      color: $muted;
    }
  }
}
