// Colors
$primary: #5cb60e;
$secondary: #20642b;
$muted: #cbd3da;
$error: #d0423b;
$warning: #e57002;
$info: #a3a3a3;

$border-primary: #dde0ec;
$border-secondary: #808080;
$border-warning: #ffa200;

$text-primary: #46484b;
$text-secondary: #222326;
$text-muted: #909192;
$text-auxiliary: #b1b5bb;

$bg-primary: #f3f7fa;
$bg-secondary: #fff;
$bg-error: #e9baba;
$bg-error-light: #fbe9e9;
$bg-warning: #fff2db;
$bg-green-40: #f0f8da;

// Breakpoints
$breakpoint-xs: 540px;
$breakpoint-s: 768px;
$breakpoint-m: 1024px;
$breakpoint-l: 1200px;
$breakpoint-xl: 1400px;

@mixin xs {
  @media (max-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin s {
  @media (max-width: #{$breakpoint-s}) {
    @content;
  }
}

@mixin m {
  @media (max-width: #{$breakpoint-m}) {
    @content;
  }
}

@mixin l {
  @media (max-width: #{$breakpoint-l}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}

// Export variables so they can be imported in .tsx files
/* stylelint-disable property-no-unknown, order/properties-alphabetical-order */
:export {
  primary: $primary;
  secondary: $secondary;
  muted: $muted;
  error: $error;
  border-primary: $border-primary;
  text-primary: $text-primary;
  text-secondary: $text-secondary;
  text-muted: $text-muted;
  text-auxiliary: $text-auxiliary;
  bg-primary: $bg-primary;
  bg-secondary: $bg-secondary;

  breakpoint-xs: $breakpoint-xs;
  breakpoint-s: $breakpoint-s;
  breakpoint-m: $breakpoint-m;
  breakpoint-l: $breakpoint-l;
  breakpoint-xl: $breakpoint-xl;
}

/* stylelint-enable */

.box-wrapper {
  @include m {
    max-width: 100%;
    padding: 0 20px 50px;
  }
}
