/* stylelint-disable max-nesting-depth */
@import '../../variables.module';

.container {
  display: inline-flex;

  &.l {
    @include m {
      width: 100%;
    }
  }

  &.wide {
    display: block;
  }

  :focus-within {
    box-shadow: 0 0 8px 2px rgba($primary, 60%);
  }
}

.ripples {
  border-radius: 3px;
  width: 100%;
}

.loader-wrapper {
  pointer-events: none;
  position: absolute;
}

.loader-wrapper-l {
  left: 0;
  width: 30px;
}

.loader-wrapper-m {
  left: 0;
  width: 30px;
}

.loader-wrapper-s {
  left: 12px;
}

.button {
  align-items: center;
  background-color: $primary;
  border-radius: 3px;
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.3;
  outline: none;
  overflow: visible;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &.s {
    font-size: 8px;
    min-height: 25px;
    min-width: 60px;
    padding: 4px 10px;
  }

  &.m {
    font-size: 10px;
    min-height: 30px;
    min-width: 130px;
    padding: 4px 45px;
  }

  &.l {
    font-size: 17px;
    min-height: 48px;
    min-width: 320px;
    padding: 8px 45px;

    @include s {
      min-width: auto;
    }
  }

  &.wide {
    min-width: 100%;
  }

  &.pending {
    background-color: darken($primary, 5%);
    color: $primary;
    position: relative;

    &:disabled {
      border-color: $muted;
      color: #fff;
      cursor: wait;
    }
  }

  &:hover {
    background-color: darken($primary, 5%);
  }

  &:disabled {
    background-color: $muted;
    cursor: not-allowed;
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: #fff;
    }

    &:disabled {
      background-color: transparent;
      border-color: $muted;
      color: $muted;
    }
  }
}
