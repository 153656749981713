@import '../../variables.module';

.offer-wrapper {
  margin: 20px 0 0;
}

.row {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 40px;
}

.bold-info {
  font-weight: 900;
}

.hr {
  background-color: $border-primary;
  border-width: 0;
  height: 1px;
  margin: 0;
}

.hr-first {
  background-color: $border-primary;
  border-width: 0;
  height: 1px;
  margin: 0;

  @include s {
    display: none;
  }
}
