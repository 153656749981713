@import '../../variables.module';

.stepper {
  background-color: #fff;
  border-left: 1px solid $border-primary;
  border-radius: 3px 3px 0 0;
  border-right: 1px solid $border-primary;
  border-top: 1px solid $border-primary;
  display: flex;
  height: 48px;
  line-height: 48px;
  overflow: visible;
  width: 139.5px;
}

.decrease,
.value,
.increase {
  border: 0;
  border-bottom: 3px solid $border-primary;
}

.decrease:hover {
  border-bottom: 3px solid $primary;
}

.increase:hover {
  border-bottom: 3px solid $primary;
}

.error-message {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  color: $error;
  display: flex;
  font-size: 14px;
  width: 100%;
}

.value {
  background: transparent;
  border-radius: 0;
  color: $text-primary;
  font-size: 18px;
  padding: 4px 0 0;
  text-align: center;
  vertical-align: top;
  width: 46px;

  &:focus {
    border-bottom-color: $primary;
    outline: none;
  }
}

.value::-webkit-inner-spin-button,
.value::-webkit-outer-spin-button {
  appearance: none;
  height: auto;
}

.stepper svg {
  height: 48px;
  vertical-align: top;
}

.stepper .button {
  text-align: center;
  width: 46px;
}

.increase {
  border-left: 1px solid $border-primary;
}

.decrease {
  border-right: 1px solid $border-primary;
}
