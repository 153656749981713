@import '../../variables.module';

.container {
  display: flex;
  flex-direction: column;

  @include xs {
    border-width: 0;
  }
}

.head {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 30px 25px;
}

.title {
  align-items: center;
  display: flex;
  font-size: 18px;
  margin-right: 25px;
}

.content {
  padding: 0 25px 30px;
}

.chevron {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;

  &.open {
    transform: rotate(-90deg);
  }
}

.accordion {
  max-height: 0;
  overflow: hidden;
  transition: all 500ms ease-out;

  &.open {
    max-height: 1000px; // Workaround for height:auto transition
    opacity: 1;
  }

  &.close {
    max-height: 0;
    opacity: 0;
  }
}

.icon {
  display: flex;
  justify-content: center;
  margin-right: 15px;
  width: 30px;
}
