/* stylelint-disable max-nesting-depth */
@import '../../variables.module';

.wrapper {
  display: flex;
  flex-direction: column;
}

.read-more {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 35px;
}

.read-more-label {
  color: $primary;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.read-more-label:hover {
  color: $secondary;
  transition: color 0.2s ease-in-out;
}

.chevron {
  cursor: pointer;
  height: 12px;
  margin-right: 8px;
  transition: transform 0.2s ease-in-out;
}

.up {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.none {
  display: none;
}

.container {
  cursor: pointer;
  display: block;
  height: 130px;
  margin-bottom: 12px;
  overflow: hidden;
  padding-left: 35px;
  position: relative;
  user-select: none;

  &.expanded {
    height: auto;
  }
}

.container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark {
  border: 1px solid #dcdfe2;
  border-radius: 5px;
  box-sizing: border-box;
  height: 25px;
  left: 0;
  position: absolute;
  top: 1em;
  transition: border 0.2s ease-in-out;
  width: 25px;
}

.checkmark:hover {
  border: 1px solid $primary;
  transition: border 0.2s ease-in-out;
}

.invisible {
  display: none;
}

.invisible:checked ~ .checkmark {
  background-color: $primary;
  border-color: $primary;
}

.invisible:checked ~ .error {
  background-color: $primary;
  border: 0;
}

.invisible:disabled ~ .checkmark {
  background-color: #eee;
  border-color: #eee;
}

.error {
  background: $bg-error;
  border: 1px solid $error;
}

.checkmark::after {
  content: '';
  display: none;
  position: absolute;
}

.invisible:checked ~ .checkmark::after {
  display: block;
}

.container .checkmark::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 10px;
  left: 8px;
  top: 4px;
  transform: rotate(45deg);
  width: 5px;
}

.collapsed {
  margin: 0;
  overflow: hidden;
}

.overlay {
  background-color: transparent;
  // stylelint-disable function-parentheses-space-inside
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  // stylelint-enable function-parentheses-space-inside
  height: 130px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.error-message {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  color: $error;
  display: flex;
  font-size: 14px;
  margin: 10px 0;
  width: 100%;
}
