@import '../../variables.module';

.help-wrapper {
  display: flex;
}

.help-info-wrapper {
  margin-left: 20px;
  max-width: 300px;
}

.information-text {
  color: $text-muted;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  a {
    display: inline-block;
  }
}

.icon {
  position: relative;
  top: 5px;
}
