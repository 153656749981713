@import '../../variables.module';

.container {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  min-height: 150px;
  overflow: hidden;
  position: fixed;
  right: 30px;
  top: 30px;
  width: 370px;

  @include m {
    border-radius: 0;
    box-shadow: none;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.cta-container {
  align-items: center;
  background-image: url('./video-cta-background-desktop.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 150px;

  @include m {
    background-image: url('./video-cta-background-mobile.jpg');
  }
}

.content {
  &-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 220px;
    padding: 22px 20px 16px 25px;
  }

  &-text {
    &.content-title {
      font-weight: 800;
      letter-spacing: -0.225px;
      line-height: 20px;
      margin-bottom: 9px;
    }

    &.content-description {
      color: $text-primary;
      letter-spacing: -0.175px;
      line-height: 13px;
      margin-bottom: 6px;
    }

    &.link.content-link {
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      margin-bottom: 2px;
      padding: 0;
    }
  }
}

.close-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 24px;

  svg {
    @include m {
      height: 12px;
      width: 12px;
    }
  }
}
