@import '../../variables.module';

.timer {
  color: $primary;
  font-size: 15px;

  @include s {
    font-size: 14px;
  }
}
