@import '../variables.module';

.wrapper {
  background-color: $bg-primary;
  display: flex;
  min-height: 100vh;
  width: 100%;

  @include m {
    flex-direction: column;
  }
}
