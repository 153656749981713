@import '../../variables.module';

.video-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.close-icon {
  cursor: pointer;
  font-size: 24px;
  position: fixed;
  right: 20px;
  top: 20px;

  /* stylelint-disable selector-max-compound-selectors */
  svg path {
    fill: #fff;
  }
}

.desktop-video-container {
  background-color: #000;
  display: block;
  height: 100%;
  max-height: 530px;
  max-width: 940px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.mobile-video-container {
  background-color: #000;
  display: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (orientation: portrait) {
    max-height: calc(100vh - 140px);
    max-width: 620px;
  }

  @media screen and (orientation: landscape) {
    max-height: 100%;
    max-width: calc(100vw - 140px);
  }
}

.desktop-video-container iframe,
.mobile-video-container iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop-video,
.mobile-video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@include m {
  .desktop-video-container {
    display: none;
  }

  .mobile-video-container {
    display: block;
  }
}
