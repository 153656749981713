@import '../../variables.module';

.container {
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 15px 5px 15px 15px;
  width: 100%;

  &.error {
    background-color: $bg-error-light;
    border: $error 1px solid;
  }

  &.warning {
    background-color: $bg-warning;
    border: $border-warning 1px solid;
    color: $warning;
  }
}

.close {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 10px;
  position: relative;
  top: 1px;

  &:active {
    background-color: darken($bg-error-light, 5%);
  }
}

.icon {
  height: 12px;
  width: 12px;
}
