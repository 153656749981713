@import '../../variables.module';

.typography {
  color: $text-primary;
  margin: 0;
  padding: 0;
  width: 100%;

  &.xs {
    color: $text-muted;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }

  &.s {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  &.m {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;

    @include s {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.l {
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
  }

  &.xl {
    color: $text-secondary;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;

    @include s {
      font-size: 28px;
      line-height: 30px;
    }
  }
}
