@import '../../variables.module';

@keyframes errorSlide {
  from {
    height: 0;
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }

  to {
    height: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.field {
  background-color: #fff;
  border: 1px solid $border-primary;
  border-bottom-width: 2px;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.stackable {
    border-bottom-width: 1px;
  }

  &:focus-within {
    border-bottom-color: $primary;
    border-bottom-width: 2px;
  }
}

.input {
  border: 0;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 10px 15px 0;
  width: 100%;
}

.input:focus + label {
  transform: scale(0.7) translate3d(0, -40%, 0);
}

.badges {
  align-items: center;
  display: flex;
  isolation: isolate;
  justify-content: center;

  .badge {
    display: block;
    padding: 0 10px;
  }

  .post-fix {
    align-items: center;
    display: flex;
    padding-left: 0;
  }

  .password-icon {
    cursor: pointer;
    padding-left: 0;
  }
}

.divider {
  background-color: $border-primary;
  height: 30px;
  width: 1px;
}

.error-icon {
  height: 100%;
}

.label {
  align-items: center;
  bottom: 0;
  color: $text-muted;
  display: flex;
  left: 15px;
  margin: auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: left;
  transition: transform 100ms ease-in-out;

  &.float {
    transform: scale(0.7) translateY(-40%);
  }
}

.error-message {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  color: $error;
  display: flex;
  font-size: 14px;
  width: 100%;
}

.error-message-be {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  background-color: $bg-error-light;
  border: 1px solid $error;
  border-top-style: none;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 10px;

  span {
    font-weight: 600;
  }
}

.error-message-be a {
  /* stylelint-disable-next-line declaration-no-important -- Storybook is overriding some styles */
  color: $text-primary !important;
  font-weight: 600;
}

.input-error,
.label-error {
  color: $error;
}

.field-error {
  background-color: $bg-secondary;
  border: 1px solid $error;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.divider-error {
  background-color: $error;
  height: 30px;
  width: 1px;
}
