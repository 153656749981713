@import '../../variables.module';

.accordion {
  background-color: $bg-secondary;
  border: 1px solid $border-primary;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 0;

  @include xs {
    border-width: 0;
  }

  > div {
    border-bottom: 1px solid $border-primary;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.tab {
  position: relative;
}

.divider {
  align-items: center;
  background-color: #fff;
  border: 1px solid $border-primary;
  border-radius: 50%;
  color: $text-auxiliary;
  display: flex;
  font-size: 10px;
  height: 40px;
  justify-content: center;
  left: Calc(50% - 20px);
  position: absolute;
  text-transform: uppercase;
  top: -22px;
  width: 40px;
}
