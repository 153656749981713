@import '../../variables.module';

@keyframes bottomFadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes topFadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.blur {
  filter: blur(4px);
  pointer-events: none;
  transition: filter ease-out 300ms;
  user-select: none;
}

.no-blur {
  filter: none;
  pointer-events: auto;
  transition: filter ease-out 300ms;
  user-select: auto;
}

.wrapper {
  color: $text-primary;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;

  @include m {
    flex-direction: column;
  }

  .value {
    font-size: 24px;
    font-weight: 700;
    margin: 0;

    @include m {
      font-size: 20px;
    }

    &-small {
      margin: 0;
    }
  }

  .unit {
    font-size: 12px;
    font-weight: 400;

    @include m {
      font-size: 10px;
    }

    &-small {
      font-size: 18px;
      margin: 0;
    }
  }

  &.no-sidebar {
    grid-template-areas: 'progress main';
    grid-template-columns: 23% auto;

    @include l {
      grid-template-columns: 26% auto;
    }

    &::after {
      left: 23%;

      /* stylelint-disable max-nesting-depth */
      @include l {
        left: 26%;
      }

      /* stylelint-enable max-nesting-depth */
    }
  }
}

.sidebar {
  background-color: $bg-secondary;
  border-right: 1px solid $border-primary;
  display: flex;
  flex-direction: column;
  padding: 50px 30px 16px;
  position: relative;
  width: 200px;
  z-index: 2;

  @include m {
    display: none;
  }
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 102px);
  position: sticky;
  top: 51px;
}

.hr {
  background-color: $border-primary;
  border-width: 0;
  height: 1px;
  margin: 18px 0;
}

.section {
  margin-bottom: 10px;
}

.logo {
  margin-bottom: 200px;
}

.small-logo {
  position: absolute;
  right: 20px;
}

.progress-wrapper {
  position: sticky;
  top: 51px;

  @include m {
    position: static;
  }
}

.progress {
  display: flex;
  flex-direction: column;
  padding: 51px 43px;
  position: relative;
  width: 200px;
  z-index: 1;

  @include m {
    padding: 0;
    width: 100%;
  }
}

.content {
  border-left: 1px solid $border-primary;
  display: flex;
  flex-grow: 1;
  justify-content: right;
  max-width: 600px;
  overflow: inherit;
  padding: 56px 76px;
  position: relative;

  @include m {
    border-left: 0;
    max-width: 100%;
    padding: 0;
  }
}

.bottom-fade-in {
  animation: bottomFadeIn 500ms ease-in-out;
}

.top-fade-in {
  animation: topFadeIn 500ms ease-in-out;
}

.nav {
  display: flex;
  justify-content: flex-end;
  max-width: 600px;
  position: absolute;
  right: 76px;
  width: 100%;

  @include m {
    display: none;
  }
}

.main {
  margin-top: 100px;

  @include m {
    margin: auto;
    margin-top: 47px;
    max-width: 600px;
  }

  &.video-banner-spacing {
    margin-top: 160px;

    @include m {
      margin-top: 47px;
    }
  }
}

.footer {
  margin-top: auto;

  .footer-text {
    color: #bdc4c9;
    margin-top: 20px;
  }
}

.desktop {
  @include m {
    display: none;
  }
}

.mobile {
  display: none;

  @include m {
    background-color: $bg-secondary;
    border-bottom: 1px solid $border-primary;
    display: block;
    padding: 0 20px;
    position: relative;
    z-index: 2;
  }
}

.header {
  align-items: center;
  display: flex;
  height: 50px;
}

.progress-bar {
  margin: 0 70px;
}

.details {
  border-top: 1px solid $border-primary;
  display: flex;
  padding: 8px 38px 13px 0;
  position: relative;
}

.details-cell {
  padding-left: 16px;
  position: relative;
  width: 50%;

  &::before {
    border-left: 1px solid $border-primary;
    content: '';
    height: 27px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:first-child {
    padding-left: 0;
    padding-right: 16px;
  }

  &:first-child::before {
    content: none;
  }
}

.footer-mobile {
  display: none;

  @include m {
    background-color: $bg-secondary;
    border-top: $muted 1px solid;
    display: block;
    margin-top: auto;
    padding: 20px;

    .nav-mobile-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .nav-mobile {
      display: flex;
      justify-content: flex-end;
    }

    .footer-mobile-text {
      color: $muted;
      font-size: 14px;
      margin-top: 15px;
    }
  }
}

.calculator {
  left: -43px;
  position: absolute;
  right: -44px;
  top: -51px;
  transform: translateX(-110%);
  transition: transform 0.2s;
  z-index: 1;

  @include m {
    left: 0;
    right: 0;
    top: 111px;
    transform: translateX(0);
    transform: translateY(-130%);
  }

  &.opened {
    transform: translateY(0);
  }
}

.edit {
  margin-top: 8px;
}

.edit-mobile {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: center;
  position: absolute;
  right: -10px;
  width: 38px;
}

.chevron {
  height: 18px;
  transition: transform 0.2s ease-in-out;
  width: 18px;

  &.opened {
    transform: rotate(-180deg);
  }
}

.video-banner-wrapper {
  border-bottom: 1px solid $border-primary;
  display: none;
  z-index: 2;

  @include m {
    display: flex;
  }
}
