@import '../../variables.module';

.section {
  display: flex;
  flex-direction: column;

  &.line::before {
    background-color: $border-primary;
    content: '';
    height: 50px;
    margin-left: 10px;
    position: relative;
    width: 2px;
  }

  &.active::before {
    background-color: $primary;
  }
}

.step {
  align-items: center;
  display: flex;
}

.circle {
  align-items: center;
  background-color: $bg-secondary;
  border: 2px solid $border-primary;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  min-width: 18px;
  width: 18px;

  &.active {
    border-color: $primary;
  }
}

.label {
  color: $border-primary;
  font-size: 18px;
  font-weight: 600;
  margin: 0 16px;

  &.active {
    color: $primary;
  }
}
