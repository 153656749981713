@import '../../variables.module';

.currency {
  align-items: baseline;
  display: flex;
}

.amount {
  font-size: 18px;
}

.unit {
  font-size: 12px;
}
